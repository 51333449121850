<template>
  <div class="page">
    <div class="list">
      <van-swipe-cell v-for="(item, index) in list" :key="index">
        <div class="item" @click="toDetail(item)">
          <div class="left_box">
            <img :src="item.picture" alt="" />
          </div>
          <div class="right_box">
            <div class="name">{{ item.associationName }}</div>
            <div class="detail_box">
              <img class="num_icon" src="./img/menNum.png" alt="" />
              <div class="num">{{ item.memberNum }}人</div>
              <!-- <img class="point_icon" src="./img/point.png" alt="" />
              <div class="point">{{ item.points }}分</div> -->
            </div>
            <div
              class="white_btn"
              v-if="item.isOwner == '1'"
              @click.stop="goEdit(item.id)"
            >
              编辑
            </div>
            <div class="yellow_btn" v-if="item.isOwner != '1'">已加入</div>
            <div
              class="yellow_btn"
              v-if="item.isOwner == '1'"
              @click.stop="goMessage(item)"
            >
              申请消息
              <span class="messageNum" v-if="item.auditingSum > 0">{{
                item.auditingSum > 99 ? "99+" : item.auditingSum
              }}</span>
            </div>
          </div>
        </div>
        <template #right>
          <div class="right_remove">
            <img @click="singOut(item)" src="./img/remove_message.png" alt="" />
          </div>
        </template>
      </van-swipe-cell>
    </div>
    <!--  -->
    <div v-if="list.length == 0" class="empty">快去加入社群吧~</div>
    <v-dialog
      confirmButtonColor="#FE9615"
      v-model="isDialog"
      @confirm="confirm"
    >
      <p class="dialog_text" v-html="dialog_text"></p>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import wx from "weixin-js-sdk";
import {
  getMyAssInfoUrl,
  removeMembersUrl,
  dissolveAssociationUrl,
} from "./api.js";
export default {
  name: "myCommunity",
  data() {
    return {
      isDialog: false,
      list: [],
      dialog_text: "",
      id: null,
    };
  },
  mounted() {
    this.getList();
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  methods: {
    goMessage(data) {
      //申请消息
      this.$router.push({
        name: "communityApplicationMessage",
        query: {
          id: data.id,
        },
      });
    },
    goEdit(id) {
      //编辑社群
      this.$router.push({
        name: "editCommunityInformation",
        query: {
          id: id,
        },
      });
    },
    getList() {
      //获取列表
      let params = {
        userId: this.userId,
      };
      this.$axios
        .get(`${getMyAssInfoUrl}`, {
          params: params,
        })
        .then((res) => {
          if (res.code === 200) {
            this.list = res.data;
          }
        });
    },
    toDetail(data) {
      //跳转社群详情
      let params = {
        id: data.id,
        isJoin: data.isJoin,
      };
      this.$router.push({
        name: "communityDetail",
        query: {
          id: data.id,
        },
      });
      // wx.miniProgram.navigateTo({
      //   url: `/xubPages/associationDetail/index?params=${JSON.stringify(
      //     params
      //   )}`,
      // });
    },
    singOut(data) {
      this.id = data.id;
      if (data.isOwner == "1") {
        this.dialog_text = `您是否确定解散社群<br>解散后不可撤回哦~`;
      } else {
        this.dialog_text = `是否退出该社群?`;
      }
      this.isDialog = true;
    },
    confirm() {
      let params = {
        userId: this.userId,
        associationId: this.id,
      };
      if (this.dialog_text == `您是否确定解散社群<br>解散后不可撤回哦~`) {
        this.$axios
          .post(`${dissolveAssociationUrl}`, this.$qs.stringify(params))
          .then((res) => {
            if (res.code === 200) {
              this.$toast("解散该社群操作成功！");
              this.getList();
            } else {
              this.$toast(res.msg);
            }
          });
      } else {
        this.$axios
          .post(`${removeMembersUrl}`, this.$qs.stringify(params))
          .then((res) => {
            if (res.code === 200) {
              this.$toast("退出该社群操作成功！");
              this.getList();
            } else {
              this.$toast(res.msg);
            }
          });
      }
      this.isDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  width: 100%;
  min-height: 100vh;
  padding-top: 26px;
  box-sizing: border-box;
  overflow-x: hidden;
  background: #fafafa;
  .empty {
    color: #969799;
    font-size: 28px;
    text-align: center;
  }
  .dialog_text {
    font-size: 32px;
    font-weight: 600;
    color: #333333;
    text-align: center;
    padding: 86px 124px 58px;
    white-space: nowrap;
  }

  .right_remove {
    height: 100%;
    background: #fafafa;
    padding: 0 22px;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    img {
      width: 74px;
      height: 74px;
    }
  }

  .list {
    width: 100%;
    padding: 0 28px;
    box-sizing: border-box;

    .van-swipe-cell {
      overflow: hidden;
      margin-bottom: 18px;
      // box-shadow: 0px 4px 16px 0px rgba(225, 225, 225, 0.5);
    }

    .item {
      width: 100%;
      height: 220px;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(225, 225, 225, 0.5);
      border-radius: 16px;
      display: flex;
      overflow: hidden;

      .left_box {
        width: 224px;
        height: 100%;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .right_box {
        box-sizing: border-box;
        padding: 20px;
        flex: 1;
        height: 100%;
        position: relative;

        .name {
          width: 400px;
          font-size: 32px;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.85);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .tag {
          font-size: 24px;
          color: rgba(0, 0, 0, 0.5);
        }

        .white_btn {
          position: absolute;
          bottom: 76px;
          right: 0;
          width: 194px;
          height: 62px;
          box-shadow: 0px 4px 8px 0px rgba(225, 225, 225, 0.5);
          border-radius: 16px 0px 16px 0px;
          text-align: center;
          border: 2px solid;
          line-height: 62px;
          font-size: 26px;
          color: #fe9616;
        }

        .yellow_btn {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 194px;
          height: 62px;
          background: linear-gradient(180deg, #ffc055 0%, #fe9615 100%);
          box-shadow: 0px 4px 8px 0px rgba(225, 225, 225, 0.5);
          border-radius: 16px 0px 16px 0px;
          text-align: center;
          line-height: 62px;
          font-size: 26px;
          color: #ffffff;
          .messageNum {
            box-sizing: border-box;
            padding: 0 4px;
            display: block;
            position: absolute;
            top: -10px;
            right: 0px;
            min-width: 36px;
            height: 36px;
            line-height: 36px;
            font-size: 24px;
            text-align: center;
            border-radius: 50%;
            background: rgba(224, 32, 32, 1);
          }
        }

        .detail_box {
          position: absolute;
          bottom: 12px;
          left: 20px;
          display: flex;
          align-items: center;

          .num_icon {
            width: 24px;
            height: 16px;
          }

          .num {
            font-size: 24px;
            color: rgba(0, 0, 0, 0.5);
            margin-left: 6px;
          }

          .point_icon {
            margin-left: 34px;
            width: 18px;
            height: 16px;
          }

          .point {
            margin-left: 6px;
            font-size: 24px;
            color: rgba(0, 0, 0, 0.5);
          }
        }
      }
    }
  }
}
</style>
